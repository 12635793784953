import $ from 'jquery'

class Modals {
    constructor(modalTrigger, modalDeactivate, modalInitClass) {
        this.elements = {
            modalTrigger: $(modalTrigger), 
            documentBody: $('body'),
            modalDeactivator: $(modalDeactivate), 
            modalInitClass: modalInitClass
        }
        this.events()
    }

    events() {
        this.elements.modalTrigger.on('click', this.initModal.bind(this))
        this.elements.modalDeactivator.on('click', this.deactivateModal.bind(this))
    }

    initModal(e) {
        e.preventDefault()
        this.elements.documentBody.addClass(this.elements.modalInitClass)   
    }

    deactivateModal() {
        this.elements.documentBody.removeClass(this.elements.modalInitClass)  
    }
}

export default Modals