(function($) {
    'use strict';

 /**

 * Checks the number of items in the tabbed content builder and reduces in size when greater than 4.
 * @TabbedContentSizeChecker - Function name, with UI selectors below relating to that specific function.
 * @this.events - Conditional check to determine if tabbed items are greater than 4, if true add class 'is-small' .
 * @return this.events() - invoke events() function to init functionality.

 **/


function TabbedContentSizeChecker() {
    this.tabbedContentListItem = $('.c-tabbed-content__tabs li');
    this.tabbedContentTabs = $('.c-tabbed-content__tabs');

    this.events = function() {
        if( this.tabbedContentListItem.length > 4 ) {
            this.tabbedContentTabs.addClass('is-small');
        }
    };

    this.events();
}

new TabbedContentSizeChecker();


function SetRowHeight() {
    this.ctaBreakerLeft = $('.c-cta--layout-breaker-left');
    this.rowMarginNone = $('.c-row--margin-none');

    this.events = function() {
        this.ctaBreakerLeft.closest(this.rowMarginNone).addClass('is-set-height');
    };

    this.events();
}

new SetRowHeight();

/**

 * Toggles Primary Menu and removes active class from secondary offcanvas nav. Adds styles to Navicon when clicked.
 * @MenuToggle - Function name, with UI selectors below relating to that specific function.
 * @this.events - Handles the event listener type(click) and then calls @toggleMenu function.
 * @this.toggleMenu - Toggles active class for the primary menu & navicon, removes active state of the secondary menu.
 * @return this.events() - invoke events() function to init functionality.

 **/

 function MenuToggle() {
    this.navicon = $('.c-navicon');
    this.primaryMenu = $('.c-nav-primary .menu');
    this.secondaryMenu = $('.c-nav-secondary');
    this.pageMain = $('.s-main');
    this.documentBody = $('body');
    this.pageHeader = $('.c-page-header');
    this.translationNav = $('.c-nav-translation');
    this.topLevelNav = ['.menu__item--solutions',
                        '.menu__item--products',
                        '.menu__item--services',
                        '.menu__item--partners',
                        '.menu__item--about',
                        '.menu__item--news'];

    this.events = function() {
        this.navicon.on('click', this.toggleMenu.bind(this));
        this.navicon.on('click', this.toggleNavState.bind(this));
        this.pageMain.on('click', this.toggleNavState.bind(this));
        this.pageMain.on('click', this.removeSecondaryNavAnchor.bind(this));
        this.pageMain.on('click', this.removeNavigationActiveClass.bind(this));
    };

    this.toggleMenu = function() {
        this.primaryMenu.toggleClass('is-active');
        this.secondaryMenu.removeClass('is-active');
        this.navicon.toggleClass('is-active');
        this.documentBody.toggleClass('is-active');
    };

    this.toggleNavState = function() {
        this.documentBody.removeClass('is-active');
        this.pageMain.removeClass('is-active');
        this.pageHeader.removeClass('is-active');

        if( $('body').hasClass('body--home') ) {
            this.pageHeader.removeClass('is-active-home');
            this.translationNav.removeClass('color-swap');
        }

        if($(window).width() >= 1200) {
            this.secondaryMenu.removeClass('is-active');
        }
    };

    this.removeSecondaryNavAnchor = function() {
        $(this.topLevelNav.join()).removeClass('is-active');
    };

    this.removeNavigationActiveClass = function() {
        this.documentBody.removeClass('navigation--is-active');
    }

    this.events();
}

new MenuToggle();

/**

 * Adds new HTML attribute to the grid filter label and appends span class to secondary menu.
 * @pageManipulators - Function name, with UI selectors below relating to that specific function.
 * @this.addAttribute - Handles the insertion of the new html attribute passed through as a string primative type.
 * @return this.addAttribute() - invoke events() function to init functionality.

 **/

 function PageManipulators() {
    this.gridFilterLabel = $(".c-grid-filter--cta .c-grid-filter__filter__label");
    this.gridFilterLabelDownload = $(".c-grid-filter--cta-left-aligned .c-grid-filter__filter__label");
    this.secondaryMenu = $('.c-nav-secondary');

    this.addAttribute = function() {
        this.gridFilterLabelDownload.html("");
        this.secondaryMenu.append('<span class="menu__item--toggle">Back</span>');
    };

    this.addAttribute();
 }

 new PageManipulators();


  /**

 * Removed active class from secondary menu when menu item toggle is clicked.
 * @SeondaryMenuRemove - Function name, with UI selectors below relating to that specific function.
 * @this.events - Handles the event listener type(click) and then calls @removeSecondaryMenu function.
 * @this.removeSecondaryMenu - Removes the active class from secondary menu.
 * @return this.events() - invoke events() function to init functionality.

 **/

 function SeondaryMenuRemove() {
    this.menuToggleItem = $('.menu__item--toggle');
    this.secondaryMenu = $('.c-nav-secondary');
    this.pageHeader = $(".c-page-header");

    this.events = function() {
        this.menuToggleItem.on('click', this.removeSecondaryMenu.bind(this));
    };

    this.removeSecondaryMenu = function() {
        this.secondaryMenu.removeClass('is-active');
        this.pageHeader.removeClass('is-active');

    };

    this.events();
 }

 new SeondaryMenuRemove();

 /**

 * Toggles offcanvas elements that are linked to the top level navigation items.
 * @OffcanvasToggles - Function name, with UI selectors below relating to that specific function.
 * @this.events - Handles the event listener type of 'click' which called a number of functions.
 * @return this.events() - invoke events() function to init functionality.

 **/


function OffcanvasToggles() {
    this.secondaryMenu = $('.c-nav-secondary');
    this.navicon = $('.c-navicon');
    this.primaryMenu = $('.c-nav-primary .menu');
    this.pageHeader = $(".c-page-header");
    this.closeButton = $('.c-button--close');
    this.documentBody = $('body');
    this.translationNav = $('.c-nav-translation');
    this.pageMain = $('.s-main');
    this.topLevelNavItems = ['.menu__item--solutions',
                             '.menu__item--products',
                             '.menu__item--services',
                             '.menu__item--partners',
                             '.menu__item--about',
                             '.menu__item--news'];

    this.events = function() {
        $(this.topLevelNavItems[0]).on('click', this.addSolutionsOffcanvas.bind(this));
        $(this.topLevelNavItems[1]).on('click', this.addProductsOffcanvas.bind(this));
        $(this.topLevelNavItems[2]).on('click', this.addServicesOffcanvas.bind(this));
        $(this.topLevelNavItems[3]).on('click', this.addPartnersOffcanvas.bind(this));
        $(this.topLevelNavItems[4]).on('click', this.addAboutOffcanvas.bind(this));
        $(this.topLevelNavItems[5]).on('click', this.addNewsOffcanvas.bind(this));
        this.closeButton.on('click', this.removeSecondaryNav.bind(this));
    };

    this.removeSecondaryNav = function() {
        this.secondaryMenu.removeClass('is-active');
        this.documentBody.removeClass('is-active');
        this.removeMenuToggle();

        if( $('body').hasClass('body--home') ) {
            this.pageHeader.removeClass('is-active-home');
            this.translationNav.removeClass('color-swap');
        }
    };

    this.removeMenuToggle = function() {
        $(this.topLevelNavItems.join()).removeClass('is-active');
    };

    this.changeColour = function() {
        this.documentBody.addClass('is-active');
        this.documentBody.addClass('navigation--is-active');
        this.pageHeader.addClass('is-active');
        this.primaryMenu.addClass('is-active');
        this.navicon.addClass('is-active');
        this.pageMain.addClass('is-active');

        if( $('body').hasClass('body--home') ) {
            this.pageHeader.toggleClass('is-active-home');
            this.translationNav.toggleClass('color-swap');
        }
    };

    this.addSolutionsOffcanvas = function(e) {
        this.removeSecondaryNav();
        this.changeColour();
        this.secondaryMenu.index($(".c-nav-secondary--solutions").addClass('is-active'));
        this.removeMenuToggle();

        e.preventDefault();

        setTimeout(function(){
            $('.menu__item--solutions').addClass('is-active');
        }, 300);

    };

    this.addProductsOffcanvas = function(e) {
        this.removeSecondaryNav();
        this.changeColour();
        this.secondaryMenu.index($(".c-nav-secondary--products").addClass('is-active'));
        this.removeMenuToggle();

        e.preventDefault();

        setTimeout(function(){
            $('.menu__item--products').addClass('is-active');
        }, 300);
    };

    this.addServicesOffcanvas = function(e) {
        this.removeSecondaryNav();
        this.changeColour();
        this.secondaryMenu.index($(".c-nav-secondary--services").addClass('is-active'));
        this.removeMenuToggle();

        e.preventDefault();

        setTimeout(function(){
            $('.menu__item--services').addClass('is-active');
        }, 300);
    };

    this.addPartnersOffcanvas = function(e) {
        this.removeSecondaryNav();
        this.changeColour();
        this.secondaryMenu.index($(".c-nav-secondary--partners").addClass('is-active'));
        this.removeMenuToggle();

        e.preventDefault();

        setTimeout(function(){
            $('.menu__item--partners').addClass('is-active');
        }, 300);
    };

    this.addAboutOffcanvas = function(e) {
        this.removeSecondaryNav();
        this.changeColour();
        this.secondaryMenu.index($(".c-nav-secondary--about").addClass('is-active'));
        this.removeMenuToggle();

        e.preventDefault();

        setTimeout(function(){
            $('.menu__item--about').addClass('is-active');
        }, 300);
    };

    this.addNewsOffcanvas = function(e) {
        this.removeSecondaryNav();
        this.changeColour();
        this.secondaryMenu.index($(".c-nav-secondary--news").addClass('is-active'));
        this.removeMenuToggle();

        e.preventDefault();

        setTimeout(function(){
            $('.menu__item--news').addClass('is-active');
        }, 300);
    };

    this.events();
 }

 new OffcanvasToggles();


 /**

 * Toggles sub navigation elements.
 * @this.events - Handles the event listener type of 'click' which has a conditional in place.
 * @return this.events() - invoke events() function to init functionality.

 **/


function ToggleSubNav() {
    this.menuItemSubNavActivator = $('.menu__item--has-children > a');

    this.events = function() {

        this.menuItemSubNavActivator.on('click', function(e) {
            var self = $(this);
            this.menuItemSubNavActivator = $('.menu__item--has-children > a');
            this.subNavString = '.sub-menu';
            this.subNav = $('.sub-menu');

            if($(window).width() < 425) {
                self.parent().find(this.subNavString).toggleClass('is-active');
                self.toggleClass('is-active');
            } else {
                this.subNav.removeClass('is-active');
                self.parent().find(this.subNavString).addClass('is-active');
                this.menuItemSubNavActivator.removeClass('is-active');
                self.addClass('is-active');
            }

            self.parent().find('.menu__item--has-children > a').addClass('is-active');

            var secondaryNavItems = self.parent().find('.menu__item');

            if( secondaryNavItems.length >= 5) {
                secondaryNavItems.closest('.c-nav-secondary').addClass('c-nav-secondary--large');
            } else {
                secondaryNavItems.closest('.c-nav-secondary').removeClass('c-nav-secondary--large');
            }

            e.preventDefault();

        } );
    };

    this.events();
}

new ToggleSubNav();


 /**

 * Toggles site search bar and hides elements it interfers with.
 * @SiteSearchToggle - Function name, with UI selectors below relating to that specific function.
 * @this.events - Handles the event listener type of 'click' which called a number of functions.
 * @return this.events() - invoke events() function to init functionality.

 **/

function SiteSearchToggle() {
    this.siteSearch = $('.site-search');
    this.siteSearchContainer = $('.site-search-container');
    this.pageHeaderButton = $('.c-page-header__right .c-button');
    this.pageLogo = $('.c-page-logo');
    this.translationMenu = $('.c-nav-translation');
    this.body = $('body')

    this.events = function() {
        this.siteSearch.on('click', this.toggleSearchBar.bind(this) );
    };

    this.toggleSearchBar = function() {
        this.siteSearchContainer.toggleClass('is-active');
        this.pageHeaderButton.toggleClass('hide');
        this.translationMenu.toggle();
        this.body.toggleClass('search-bar--is-active');

        if($(window).width() < 425 ) {
            this.pageLogo.toggleClass('hide');
        }
    };

    this.events();
}

new SiteSearchToggle();

/**

 * Adds span tag after translation menu ul element and toggles sub menu.

 **/


$('.c-nav-translation .menu__item').after('<span class="menu-translation-menu-toggle"></span>');

 /**

 * Toggles Translation Nav in Header.
 * @TranslationNavToggle - Function name, with UI selectors below relating to that specific function.
 * @this.events - Handles the event listener type of 'click' which called a number of functions.
 * @return this.events() - invoke events() function to init functionality.

 **/

function TranslationNavToggle() {
    this.translateMenuToggle = $('.menu-translation-menu-toggle');
    this.translationSubMenu = $('.c-nav-translation .sub-menu');
    this.documentBody =  $('body');
    this.menuItemParent = $('.c-nav-translation .menu__item--has-children > a');

    this.events = function() {
        this.translateMenuToggle.on('click', this.toggleNav.bind(this) );
        this.menuItemParent.on('click', this.toggleSubNav.bind(this) );
    };

    this.toggleNav = function() {
        this.translationSubMenu.toggleClass('is-active');
        this.documentBody.toggleClass('translate-nav--active');
    };

    this.toggleSubNav = function() {
        this.translationSubMenu.addClass('is-active');
        this.documentBody.addClass('translate-nav--active');
    };

    this.events();
}

new TranslationNavToggle();

 /**

 * Triggers Offcanvas to display image.
 * @OffcanvasTrigger - Function name, with UI selectors below relating to that specific function.
 * @this.events - Handles the event listener type of 'click' which called a number of functions.
 * @return this.events() - invoke events() function to init functionality.

 **/

function OffcanvasTrigger() {
    this.primaryNavItem = $('.c-nav-primary .menu__item > a');
    this.documentBody =  $('body');

    this.events = function() {
        this.primaryNavItem.on('click', this.triggerOffcanvas.bind(this) );
    };

    this.triggerOffcanvas = function() {

        setTimeout(function() {
            $('body').trigger('revealOffcanvas');
        }, 100);
    };

    this.events();
}

new OffcanvasTrigger();


 /**

 * Triggers Number Counter Animation.

 **/


 $('.c-cta--counter').waypoint({
	handler: function(direction) {

        $(this.element).find('.count').each(function () {
            var $this = $(this);
            jQuery({ Counter: $this.text() - 50 }).animate({ Counter: $this.text() }, {
                duration: 4000,
                easing: 'swing',
                step: function () {
                $this.text(Math.ceil(this.Counter));
                }
            });
        });
        this.destroy();
	},
    offset: '80%'
});

$('.c-cta--counter').waypoint({
	handler: function(direction) {

        $(this.element).find('.count--decimal').each(function () {
            var $this = $(this);
            jQuery({ Counter: 0 }).animate({ Counter: $this.text() }, {
                duration: 4000,
                easing: 'swing',
                step: function () {
                    $this.text(this.Counter.toFixed(1));
                }
            });
        });

        this.destroy();
	},
    offset: '80%'
});

$('.c-cta--counter').waypoint({
	handler: function(direction) {

        $(this.element).find('.count--comma').each(function () {
            var $this = $(this);
            jQuery({ Counter: 0 }).animate({ Counter: $this.text() }, {
                duration: 4000,
                easing: 'swing',
                step: function () {
                    $this.text((this.Counter.toFixed(1).toString().replace(".", ",")));
                }
            });
        });

        this.destroy();
	},
    offset: '80%'
});


/**

 * Counts number of characters in grid filter item/posts headings and if larger than 160 reduce font size.
 * @this.events - Checks each grid filter item and conditional to determin length of text.
 * @return this.events() - invoke events() function to init functionality.

 **/

function FilterCharacterCount() {
    this.gridFilterItem = $('.c-grid-filter__item');
    this.postItem = $('.c-posts__post');

    this.events = function() {

        this.gridFilterItem.each(function() {
            this.ctaHeading = '.c-cta__heading';
            var self = $(this);
            this.ctaHeadingLength = self.find(this.ctaHeading).text().length;

            if(this.ctaHeadingLength >= 90) {
                self.find(this.ctaHeading).addClass('is-small');
            }
        });

        this.postItem.each(function() {
            this.postHeading = '.c-post__heading';
            var self = $(this);
            this.postsHeadingLength = self.find(this.postHeading).text().length;

            if(this.postsHeadingLength >= 90) {
                self.find(this.postHeading).addClass('is-small');
            }
        });
    };

    this.events();
}

new FilterCharacterCount();

function PostSearchFilter() {
    this.postSearchProducts = $(".c-post-search--option-products .c-post-search__taxonomies option");
    this.postSearchSolutions = $(".c-post-search--option-solutions .c-post-search__taxonomies option");
    this.postSearchBlog = $(".c-post-search--option-blog .c-post-search__taxonomies option");
    var postCategories = {
            products: [
                    'Gifted',
                    'Channel Management',
                    'Central Reservations',
                    'PMS',
                    'C&B',
                    'EPoS',
                    'Assisted',
                    'Check-in',
                    'Payments',
                    'Websuite',
                    'Gifted Voucher System',
                    'Direct Booking Manger',
                    'Uncategorised' ,
                    'Best Western Feature',
                    'Channel Manager Feature',
                    'Distribution Feature',
                    'EPOS Feature',
                    'Gifted Feature',
                    'Global Offices Feature',
                    'Hotel Groups Feature',
                    'Pub Companies Feature',
                    'Serviced Apartments Feature',
                    'PMS Feature',
                    'Management Companies Featured',
                    'DBM Feature',
                    'Payments Feature',
                    'C&B Feature',
                    'Independent Hotels Feature',
                    'Home page',
                    'American case studies'
                ],
            solutions:[
                    'Best Western Hotels',
                    'Colleges and Universities',
                    'Hotel Groups',
                    'Independent Hotels',
                    'London Hotels',
                    'Pub Companies',
                    'Serviced Apartments',
                    'Uncategorised',
                    'Best Western Feature',
                    'Channel Manager Feature',
                    'Distribution Feature',
                    'EPOS Feature',
                    'Gifted Feature',
                    'Global Offices Feature',
                    'Hotel Groups Feature',
                    'Pub Companies Feature',
                    'Serviced Apartments Feature',
                    'PMS Feature',
                    'Management Companies Featured',
                    'DBM Feature',
                    'Payments Feature',
                    'C&B Feature',
                    'Independent Hotels Feature',
                    'Home page'

            ],
            blogPost: [
                    'Guestline Customer Stories',
                    'Hospitality insights & trends',
                    'Hospitality partnerships',
                    'Guestline Solutions',
                    'Uncategorised',
                    'Best Western Feature',
                    'Channel Manager Feature',
                    'Distribution Feature',
                    'EPOS Feature',
                    'Gifted Feature',
                    'Global Offices Feature',
                    'Hotel Groups Feature',
                    'Pub Companies Feature',
                    'Serviced Apartments Feature',
                    'PMS Feature',
                    'Management Companies Featured',
                    'DBM Feature',
                    'Payments Feature',
                    'C&B Feature',
                    'Independent Hotels Feature',
                    'Home page'
            ]
    };


    this.events = function() {

        this.postSearchProducts.filter(function() {

            for(var i = 0; i < postCategories.solutions.length; i++) {
                var self = $(this);

                if( $.trim(self.text()) === postCategories.solutions[i] ) {
                    self.remove();
                }
            }

            for(var i = 0; i < postCategories.blogPost.length; i++) {
                var self = $(this);

                if( $.trim(self.text()) === postCategories.blogPost[i] ) {
                    self.remove();
                }
            }

        });

        this.postSearchSolutions.filter(function() {

            for(var i = 0; i < postCategories.products.length; i++) {

                if( $.trim($(this).text()) === postCategories.products[i] ) {
                    (this).remove();
                }
            }

            for(var i = 0; i < postCategories.blogPost.length; i++) {

                if( $.trim($(this).text()) === postCategories.blogPost[i] ) {
                    (this).remove();
                }
            }

        });

        this.postSearchBlog.filter(function() {

            for(var i = 0; i < postCategories.products.length; i++) {

                if( $.trim($(this).text()) == postCategories.products[i] ) {
                    (this).remove();
                }
            }

            for(var i = 0; i < postCategories.solutions.length; i++) {

                if( $.trim($(this).text()) == postCategories.solutions[i] ) {
                    (this).remove();
                }
            }
        });
    };

    this.events();
}

new PostSearchFilter();

/**

 * Conditional Check that detects the HTML language attribute of the site loaded - If German hide zendesk iframe.
 * @this.events - Conditional Check to see if document loaded is German and if so hide zendesk iframe.
 * @return this.events() - invoke events() function to init functionality.

 **/

function IframeHideCondition() {
    this.documentHTML = $('html');
    this.zendeskIframe = $('#ze-snippet');

    this.events = function() {

        if (this.documentHTML.is(':lang(de-DE)')) {
            this.zendeskIframe.remove();
        } else {
            this.zendeskIframe.show();
        }
    };

    this.events();
}

new IframeHideCondition();

/**

 * Conditional Check that determines if Event Post types are above 150 characters and if so trim word count to that limit.
 * @this.events - Conditional Check to see if post event type text is larger than 150 characters.
 * @return this.events() - invoke events() function to init functionality.

 **/

function TrimEventPosts() {
    this.postEventButton = $('.c-post--event .c-post__text .c-post__more');
    this.postEventText = $(".c-post--event .c-post__text p");

    this.events = function() {

        this.postEventButton.each(function() {
            $(this).insertAfter($(this).parent());
        });

        this.postEventText.each(function () {
            if ($(this).text().length > 150)
                $(this).text($(this).text().substring(0,150) + '...');
        });
    };

    this.events();
}

new TrimEventPosts();

/**

 * Conditional Check that determines Grid Filter CTA post types are above/below certain length, if so add appropriate class name.
 * @this.events - Conditional Check to see if post event type text is larger than 3 ot 2 items.
 * @return this.events() - invoke events() function to init functionality.

 **/

var girdFilterItemCTA = $('.c-grid-filter--cta .c-grid-filter__grid .c-grid-filter__item')

girdFilterItemCTA.each(function() {

    if( girdFilterItemCTA.length === 3) {
        $(this).addClass('c-grid-filter__item--1-of-3');
    } else if ( girdFilterItemCTA.length === 2 ) {
        $(this).addClass('c-grid-filter__item--1-of-2');
    } else {
        girdFilterItemCTA.removeClass('c-grid-filter__item--1-of-2');
        girdFilterItemCTA.removeClass('c-grid-filter__item--1-of-3');
    }
});

/**

 * Looping over accordion items and adding is active class to the appropriate element that is clicked and closing elements that aren't
 * @this.events - For loop to determine accordion element clicked.
 * @return this.events() - invoke events() function to init functionality.

 **/

function Accordion() {
    this.accordionItem = $('.c-accordion__item');

    this.events = function() {

    for(var i = 0; i <  this.accordionItem.length; i++) {

        this.accordionItem[i].addEventListener('click', function() {
            this.accordionItem = $('.c-accordion__item');

            this.accordionItem.not($(this)).removeClass('accordion--is-active');
            this.accordionItem.not($(this)).removeClass('is-active');
            $(this).toggleClass("accordion--is-active");
        });
    }
    };

    this.events();
}

new Accordion();

var clampSelectors = [
    {
        cssSelector: '.bf_title_1',
        lineLength: 2
    }
];

$.each(clampSelectors, function(index, clamp) {
    $(clamp.cssSelector).each(function(index, element) {
        $clamp(element, {clamp: clamp.lineLength});
    });
});

})(jQuery);

import Modals from './modules/Modals'
import ScrollLock from './modules/ScrollLock'

const headerSecondaryModalActivator = new Modals('.c-page-header .c-page-header__right .c-button--secondary', '.c-offcanvas--form .c-button.no-variation', 'offcanvas-form-secondary--is-active')
const headerPrimaryModalActivator = new Modals('.c-page-header .c-page-header__right .c-button--primary', '.c-offcanvas--form .c-button.no-variation', 'offcanvas-form-primary--is-active')
const offcanvasLock = new ScrollLock('.c-offcanvas--form', '.c-button--secondary', '.c-offcanvas--form .c-button.no-variation')
const offcanvasLockClickDimensions = new ScrollLock('.c-offcanvas--form', '.c-button--primary', '.c-offcanvas--form .c-button.no-variation')