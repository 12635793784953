import $ from 'jquery'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

class ScrollLock {
    constructor(targetEl, lockActivator, lockDeactivator) {
        this.elements = {
            targetEl: targetEl,
            lockActivator: lockActivator,
            lockDeactivator: lockDeactivator
        }
        this.elements.targetEl = null
        this.events()
    }

    events() {
        $(this.elements.lockActivator).on('click', this.lockScroll.bind(this))
        $(this.elements.lockDeactivator).on('click', this.disableLockScroll.bind(this))
    }

    lockScroll() {
        this.elements.targetEl = $(this.elements.targetEl)
        disableBodyScroll(this.elements.targetEl);
    }

    disableLockScroll() {
        enableBodyScroll(this.elements.targetEl);
        clearAllBodyScrollLocks();
    }
}

export default ScrollLock